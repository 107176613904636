import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://8f98e8491a9263cb419ee17492ea82b7@o4506345672081408.ingest.sentry.io/4506345673326592",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});